@import 'tellco-theme';
@import 'styles-mobile';

html,
body {
	margin: 0;
	height: 100%;
	font-family: Roboto, sans-serif;
}

.toolbar-line {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.font-color {
	color: #666666;
}

.form-title {
	margin-bottom: 10px;
	font-weight: bold;
}

.form-content {
	width: 80%;
}

.form-content-center {
	text-align: center;
}

.in-line {
	display: inline-block;
}

.right-10-space {
	padding-right: 10px;
}

.left-10-space {
	padding-left: 10px;
}

.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.center {
	margin: auto;
	width: 80%;
	padding: 10px;
}

.responsive {
	width: 100%;
	height: auto;
}

.mat-tooltip {
	font-size: 14px;
}

.mat-input-element:disabled {
	color: black !important;
}

.section {
	margin-top: 15px;
}

.section-title {
	float: left;
	padding: 0 5px;
	margin: -20px 0 0 10px;
	background: white;
	position: absolute;
	font-style: italic;
	font-size: 1.1em;
	font-weight: lighter;
}

.card-section-title {
	float: left;
	padding: 0 5px;
	margin: -15px 0 0 10px;
	background: white;
	position: absolute;
	font-style: italic;
	font-size: 1.1em;
	font-weight: lighter;
}

.section-content {
	margin-left: 5px;
	margin-right: 5px;
}

.card-section {
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	padding: 5px;
}

.sub-section {
	border-top: 1px solid rgba(0, 0, 0, 0.12);
	padding: 10px;
	margin-top: 15px;
}

.sub-section-no-border {
	padding: 10px;
}

.sub-section-no-title {
	padding: 10px;
	margin-top: 15px;
}

.sub-section-title {
	float: left;
	padding: 0 5px;
	margin: -20px 0 0 10px;
	background: white;
	position: absolute;
	font-style: italic;
	font-size: 1.1em;
	font-weight: lighter;
}

.sub-section-content {
	margin-left: 5px;
	margin-right: 5px;
}

.option-sub {
	font-size: 12px;
	color: gray;
}

.top-select-margin {
	margin-top: 18px !important;
}

.align-toggle-button-and-input {
	margin-top: 12px;
}

.top-button-margin {
	margin-top: 15px !important;
}

.toggle-button-side-label {
	margin-top: 30px;
	margin-right: 5px;
}

.width-100 {
	width: 100%;
}

.margin-top-15 {
	margin-top: 15px;
}

.margin-top-10 {
	margin-top: 10px;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-wrap nc-toggle-buttons {
	margin-bottom: 10px;
}

.no-margin-bottom {
	margin-bottom: 0px !important;
}

.readonly-form {
	text-align: left;

	& tr {
		& td:nth-child(1) {
			color: var(--primary-color);
		}

		& td:nth-child(2) {
			font-weight: 500;
		}
	}
}

.mat-option-title {
	font-size: 14px;
}

.mat-option-footer {
	font-size: 12px;
	color: var(--primary-darker-color);
}
