@media only screen and (max-width: 900px) {
	.center {
		width: 95% !important;
		padding: 5px !important;
	}

	body {
		font-size: 0.8em;
	}

	.app-title {
		font-size: 15px !important;
	}

	.right-10-space {
		padding-right: 0 !important;
	}

	.left-10-space {
		padding-left: 0 !important;
	}

	.in-line {
		display: block !important;
	}
}
